import React from 'react';
import HeroSlider from '../../components/UI/HeroSlider';
import Helmet from '../../components/Helmet/Helmet';
import Header from '../../components/Header/Header';
import AboutTemplate from '../../components/UI/AboutTemplate';



const Content = {
    title: `Self Drive Cars In Nagercoil | Self Drive Cars Nagercoil | Rent A Car In Nagercoil | Rent A Car Nagercoil | Self
    Drive Cars In Kanyakumari | Self Drive Cars Kanyakumari | Rent A Car In Kanyakumari | Rent A Car Kanyakumari | Best
    Self Drive Cars Rent In Nagercoil`,
    desc: `Kanyakumari, a stunning destination at the southern tip of India, offers travelers an extraordinary combination of natural beauty, rich culture, and historic landmarks. From breathtaking sunrises and sunsets to the convergence of three seas, Kanyakumari is home to famous temples, serene beaches, and lush hill stations. Whether you're drawn to its peaceful surroundings or its adventurous opportunities, Kanyakumari promises a memorable getaway for all types of travelers. 

For those looking to explore the hidden gems and lesser-known corners of Kanyakumari, self-drive car rentals provide the ultimate way to navigate the city and its surroundings. While local transport can be unreliable, renting a car allows you to travel at your own pace, giving you the flexibility to visit remote attractions and make the most of your time in this beautiful region. Whether you're here for a weekend getaway or a longer vacation, a self-drive car rental in Kanyakumari enhances your travel experience by offering complete freedom. `,

    services: [
        "Car rentals in Kanyakumari",
        "Self drive car rental in Kanyakumari",
        "Best self drive car rentals in Kanyakumari",
        "Self drive car in Kanyakumari",
        "Car hire in Kanyakumari",
        "Rental car services in Kanyakumari",
        "Rental self drive cars in Kanyakumari",
        "Car rental service in Kanyakumari",
        "Best car rental in Kanyakumari",
        "Self drive cars Kanyakumari",
        "Self Drive Cars In Kanyakumari",
        "Self Drive Cars Nagercoil",
        "Self Drive Cars in Nagercoil",
        "Rent a Car Kanyakumari",
        "Rent A Car In Kanyakumari",
        "Rent A Car Nagercoil",
        "Rent a Car In Nagercoil"
    ]
};



const RentInKanyaKumari = () => {

    return (
        <Helmet title={Content.title} id="home">
            <Header />

            {/* ============= hero section =========== */}
            <section className="p-0 hero__slider-section">
                <HeroSlider />
            </section>
            {/* =========== about section ================ */}
            <section id="about">
                <AboutTemplate content={Content} />
            </section>
        </Helmet>
    )
}
export default RentInKanyaKumari;