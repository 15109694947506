import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";

// const quickLinks = [
//   {
//     path: "/self-drive-cars-nagercoil ",
//     display: "Self Drive Cars Nagercoil | Self Drive Cars in Nagercoil | Rent a Car Nagercoil | Rent a Car In Nagercoil",
//   },
//   {
//     path: "/self-drive-cars-kanyakumari",
//     display: "Self Drive Cars Kanyakumari | Self Drive Cars in Kanyakumari | Rent a Car Kanyakumari | Rent a Car In Kanyakumari",
//   },
//   {
//     path: "/self-drive-cars-marthandam",
//     display: "Self Drive Cars Marthandam | Self Drive Cars in Marthandam | Rent a Car Marthandam | Rent a Car In Marthandam",
//   },


// ];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className=" footer__logo">
              <h1>
                <Link to="/home" className=" d-flex align-items-center gap-1">
                  <i className="ri-car-line"></i>
                  <span>VDrive</span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
              Renting a car offers a flexible solution for travel needs. Whether
              for business or leisure, car rental services provide a range of
              vehicles to suit different preferences. Customers can choose from
              compact cars, sedans, SUVs, and more, ensuring the right fit for
              their journey. Options like insurance coverage and GPS navigation
              enhance the experience, making car rentals a popular choice for
              many travelers
            </p>
          </Col>

          <Col lg="6" md="6" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>

                <ListGroupItem className="p-0 mt-3 quick__link">
                  <a href="/self-drive-cars-nagercoil">Self Drive Cars Nagercoil | </a><a href="/self-drive-cars-in-nagercoil">Self Drive Cars in Nagercoil | </a><a href="/rent-a-car-nagercoil">Rent a Car Nagercoil | </a><a href="/rent-a-car-in-nagercoil">Rent a Car in Nagercoil</a>

                </ListGroupItem>
                <ListGroupItem className="p-0 mt-3 quick__link">
                  <a href="/self-drive-cars-marthandam">Self Drive Cars Marthandam | </a>
                  <a href="/self-drive-cars-in-marthandam">Self Drive Cars in Marthandam | </a>
                  <a href="/rent-a-car-marthandam">Rent a Car Marthandam | </a>
                  <a href="/rent-a-car-in-marthandam">Rent a Car in Marthandam</a>
                </ListGroupItem>
                <ListGroupItem className="p-0 mt-3 quick__link">
                  <a href="/self-drive-cars-kanyakumari">Self Drive Cars Kanyakumari | </a>
                  <a href="/self-drive-cars-in-kanyakumari">Self Drive Cars in Kanyakumari | </a>
                  <a href="/rent-a-car-kanyakumari">Rent a Car Kanyakumari | </a>
                  <a href="/rent-a-car-in-kanyakumari">Rent a Car in Kanyakumari</a>
                </ListGroupItem>
              </ListGroup>
              {/* ))} */}

            </div>
          </Col>

          <Col lg="2" md="2" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Address</h5>
              <p className="office__info">Rajalakshmi Nagar</p>
              <p className="office__info">Parvathipuram, Nagercoil</p>
              <p className="office__info">
                Phone: <a href="tel:+919385680828">+919385680828</a>
              </p>
              <p className="office__info">
                Email: <a href="mailto:vdrivecar@gmail.com">vdrivecar@gmail.com</a>
              </p>
            </div>
          </Col>

          {/* <Col lg="3" md="4" sm="12">
          <div className="mb-4">
            <h5 className="footer__link-title">Newsletter</h5>
            <p className="section__description">Subscribe our newsletter</p>
            <div className="newsletter">
              <input type="email" placeholder="Email" />
              <span>
                <i className="ri-send-plane-line"></i>
              </span>
            </div>
          </div>
        </Col> */}

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i className="ri-copyright-line"></i>Copyright {year}, Developed
                by
                <a href="https://hazotech.com/" target="blank">
                  Hazo Tech Academy
                </a>{" "}
                . All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
