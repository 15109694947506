import React from "react";
import { Container, Row, Col } from "reactstrap";

const AboutTemplate = ({ aboutClass, content }) => {
    const { title, desc, services } = content;

    return (
        <section
            className="about__section"
            style={aboutClass === "aboutPage" ? { marginTop: "0px" } : { marginTop: "0px" }}
        >
            <Container>
                <Row>
                    <Col lg="12" md="12">
                        <div className="about__section-content">
                            <h2 className="section__title">{title}</h2>
                            <p className="section__description">{desc}</p>

                            {/* Services Section with Background Color */}
                            <div className="p-4 bg-light rounded">
                                <Row>
                                    {services.map((service, index) => (
                                        <Col key={index} lg="4" md="6" sm="12" className="mb-3">
                                            <div className="d-flex align-items-center gap-2">
                                                <i className="ri-checkbox-circle-line text-primary"></i>
                                                <p className="mb-0 text-dark">{service}</p>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default AboutTemplate;
