import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import Admin from "../pages/Admin";
import AdminLogin from "../pages/AdminLogin.jsx";


import SelfDriveNagercoil from "../pages/Nagercoil/self_drive_ngl.jsx";
import SelfDriveInNagercoil from "../pages/Nagercoil/self_drive_in_ngl.jsx";
import RentNagercoil from "../pages/Nagercoil/rent_ngl.jsx";
import RentInNagercoil from "../pages/Nagercoil/rent_in_ngl.jsx";

import SelfDriveKanyaKumari from "../pages/Kanyakumari/self_drive_kk.jsx";
import SelfDriveInKanyaKumari from "../pages/Kanyakumari/self_drive_in_kk.jsx";
import RentKanyaKumari from "../pages/Kanyakumari/rent_kk.jsx";
import RentInKanyaKumari from "../pages/Kanyakumari/rent_in_kk.jsx";

import SelfDriveMarthandam from "../pages/Marthandam/self_drive_mar.jsx";
import SelfDriveInMarthandam from "../pages/Marthandam/self_drive_in_mar.jsx";
import RentMarthandam from "../pages/Marthandam/rent_mar.jsx";
import RentInMarthandam from "../pages/Marthandam/rent_in_mar.jsx";

const Routers = () => {
  const isAuthenticated = () => {
    // Replace this logic with your actual authentication check
    // For example, check if 'admin_token' exists in session storage
    return !!sessionStorage.getItem('admin_token');
  };

  const ProtectedRoute = ({ element }) => {
    return isAuthenticated() ? element : <Navigate to="/adminlogin" />;
  };

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/admin" element={<ProtectedRoute element={<Admin />} />} />
      <Route path="/adminlogin" element={<AdminLogin />} />
      <Route path="/self-drive-cars-nagercoil" element={<SelfDriveNagercoil />} />
      <Route path="/self-drive-cars-in-nagercoil" element={<SelfDriveInNagercoil />} />
      <Route path="/rent-a-car-nagercoil" element={<RentNagercoil />} />
      <Route path="/rent-a-car-in-nagercoil" element={<RentInNagercoil />} />
      <Route path="/self-drive-cars-marthandam" element={<SelfDriveMarthandam />} />
      <Route path="/self-drive-cars-in-marthandam" element={<SelfDriveInMarthandam />} />
      <Route path="/rent-a-car-marthandam" element={<RentMarthandam />} />
      <Route path="/rent-a-car-in-marthandam" element={<RentInMarthandam />} />
      <Route path="/self-drive-cars-kanyakumari" element={<SelfDriveKanyaKumari />} />
      <Route path="/self-drive-cars-in-kanyakumari" element={<SelfDriveInKanyaKumari />} />
      <Route path="/rent-a-car-kanyakumari" element={<RentKanyaKumari />} />
      <Route path="/rent-a-car-in-kanyakumari" element={<RentInKanyaKumari />} />
    </Routes>
  );
};

export default Routers;
